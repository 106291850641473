<template>
    <b-card no-body>
        <b-row>
            <b-col cols="12">
                <div class="m-2">
                    <b-row>
                        <b-col
                            cols="12"
                            md="6"
                            sm="12"
                        >
                            <h1>{{ i18nT(`Finances`) }}</h1>
                            <div class="mb-1">
                                <span class="text-muted">{{ i18nT(`Showing an overview of your financial activities`) }}</span>
                            </div>
                        </b-col>
                        <b-col
                            cols="12"
                            md="6"
                            sm="12"
                        >
                            <b-dropdown
                                v-if="iCan( 'finances', 'write' )"
                                id="add-new-dropdown"
                                variant="primary"
                                class="float-right"
                                right
                            >
                                <template #button-content>
                                    <feather-icon icon="PlusCircleIcon" />
                                    {{ i18nT(`Add new`) }}
                                </template>

                                <b-dropdown-item class="text-primary" :to="{ name: 'create-income' }">
                                  <div class='text-primary'>
                                        <font-awesome-icon class="mr-25" :icon="['fas', 'plus-circle']" />
                                        {{ i18nT(`Add income`) }}
                                  </div>
                                </b-dropdown-item>

                                <b-dropdown-item class="text-danger" :to="{ name: 'create-expense' }">
                                  <div class="text-danger">
                                        <font-awesome-icon class="mr-25" :icon="['fas', 'minus-circle']" />
                                        {{ i18nT(`Add expense`) }}
                                  </div>
                                </b-dropdown-item>

                                <b-dropdown-item  :to="{ name: 'create-invoice' }">
                                  <div class='text-primary'>
                                        <font-awesome-icon class="mr-25" :icon="['fas', 'file-invoice-dollar']" />
                                        {{ i18nT(`Create invoice`) }}
                                  </div>
                                </b-dropdown-item>

                                <b-dropdown-item
                                    v-b-modal.modal-import-csv
                                    v-if="betaFeature"
                                >
                                    <div class="text-primary">
                                    <font-awesome-icon class="mr-25" :icon="['fas', 'file-import']" />
                                    {{ i18nT(`Import`) }}
                                    </div>
                                </b-dropdown-item>

                            </b-dropdown>

                        </b-col>
                    </b-row>
                </div>
                <b-card-body>
                    <b-row
                        v-if="controlVisible">
                        <b-col cols="12">
                            <div class="text-center">
                                <b-form-group>
                                    <b-form-radio-group
                                        class="d-none d-md-inline-flex w-75"
                                        id="chart-buttons"
                                        v-model="selectedFinanceType"
                                        button-variant="outline-primary"
                                        :options="chartTypes"
                                        buttons
                                        name="radios-btn-default"
                                    />
                                </b-form-group>
                            </div>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col
                            cols="12"
                            class="py-3 py-sm-0"
                            v-if="controlVisible"
                        >
                            <finance-report
                                :selectedFinanceType="selectedFinanceType" />
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" class="text-center" v-if="controlVisible">
                            <b-form-group
                                :label="i18nT(`Show the list of financial records or recorded payments`)"
                            >
                                <b-form-radio-group
                                    id="list-selector"
                                    v-model="toggleMode"
                                    :options="listOptions"
                                    button-variant="outline-primary"
                                    size="md"
                                    name="radio-btn-outline"
                                    buttons
                                ></b-form-radio-group>
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <transition-group name="fade" mode="out-in">
                        <b-row v-if="listMode== 'documents'" :key="'documents'">
                            <b-col cols="12">
                                <finance-list
                                    :selectedFinanceType="selectedFinanceType"
                                    @update:controlVisible="controlVisible = $event"
                                />
                            </b-col>
                        </b-row>
                        <b-row v-if="listMode== 'payments'" :key="'payments'">
                            <b-col cols="12">
                                <payments-list
                                    :selectedFinanceType="selectedFinanceType" />
                            </b-col>
                        </b-row>
                    </transition-group>

                </b-card-body>
            </b-col>
        </b-row>
      <!-- MODAL IMPORT CSV -->
      <modal-import-csv class-name="invoices" @imported="getFinanceTypes" :import-type="importType">
            <b-row class="pt-2">
                <b-col cols="12" md="3">
                    <h5>
                        {{ i18nT(`Select document type`) }}
                    </h5>
                </b-col>
                <b-col cols="12" md="9">
                    <v-select 
                        v-model="importType"
                        :options="importOptions"
                        label="text"
                        class="pb-1"
                        :clearable="false"
                    />
                </b-col>
            </b-row>
        </modal-import-csv>
    </b-card>


</template>

<script>
import {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BFormRadioGroup,
    BFormGroup,
    //BButton,
    BDropdown,
    BDropdownItem,
    BLink,
} from 'bootstrap-vue'
import FinanceReport from './FinanceReport.vue'
import FinanceList from './FinanceList.vue'
import vSelect from 'vue-select'
import PaymentsList from "@/views/accounting/Finances/PaymentsList.vue";
import Ripple from 'vue-ripple-directive'
import { dictToSelectArray } from '@core/utils/utils'
import ModalImportCsv from "@/views/common/ModalImportCsv/ModalImportCsv.vue";

export default {
    components: {
      ModalImportCsv,
        BRow,
        BCol,
        BCard,
        BCardBody,
        BFormRadioGroup,
        BFormGroup,
        //BButton,
        BDropdown,
        BDropdownItem,
        vSelect,
        BLink,
        FinanceReport,
        FinanceList,
        PaymentsList
    },
    directives: {
        Ripple
    },
    mounted() {
        if(this.$route.params.listMode){
            this.listMode = this.$route.params.listMode
            this.toggleMode = this.$route.params.listMode
        } else {
            this.toggleMode = 'documents'
        }
    },
    data() {
        return {
            chartTypes: [],
            toggleMode: null,
            listMode: 'documents',
            listOptions: [
                { text: this.i18nT(`Financial records`), value: 'documents' },
                { text: this.i18nT(`Recorded payments`), value: 'payments' }
            ],
            selectedFinanceType: '-1',
            financeType: 'all',
            controlVisible: true,
            importOptions: [
                {
                    text: this.i18nT(`Income`), module: 'finances', import_type: 3
                },
                {
                    text: this.i18nT(`Expense`), module: 'finances', import_type: 4
                },
            ],
            importType: {} 
        }
    },
    created() {
        this.getFinanceTypes();
    },
    methods: {
      getFinanceTypes(){
        this.$http.get(`system/financeTypes`).then(({ data }) => {
          this.chartTypes = dictToSelectArray(data.data);
          this.chartTypes.unshift({
            value: '-1',
            text: this.i18nT(`Both`)
          })
        })
      }
    },
    watch: {
        toggleMode(val, oldVal) {
            if(oldVal != val && oldVal != null){
                this.$router.replace({ name: 'finances-list', params: { listMode: val } })
            }
        },
    }
}
</script>

<style>
#chart-buttons .btn:nth-child(2).active {
    background-color: var(--success);
    color: #fff;
}
#chart-buttons .btn:nth-child(3).active {
    background-color: var(--danger);
    color: #fff;
}
.card-revenue-budget .budget-wrapper {
    padding: 2rem 3rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
}


</style>
