<template>
    <div>
        <b-card no-body>
            <div class="m-2">
                <div v-if="controlVisible">
                    <b-row >
                        <b-col cols="12">
                            <h1 class="float-left">{{ i18nT(`Financial records`) }}</h1>
                        </b-col>
                    </b-row>
                    <div class="mb-1">
                        <span class="text-muted">{{ i18nT(`Showing`) }} {{
                                dataMeta.from
                            }} {{ i18nT(`to`) }} {{ dataMeta.to }} {{ i18nT(`of`) }} {{
                                dataMeta.of
                            }} {{ i18nT(`entries`) }}</span>
                    </div>
                    <b-row>
                        <b-col
                            cols="12"
                            md="3"
                        >
                            <b-form-group
                                :label="i18nT(`Reporting period`)"
                                label-for="paymentType"
                                v-if="controlVisible"
                            >

                                <b-input-group>
                                    <b-input-group-prepend>
                                        <b-button
                                            variant="primary"
                                            @click.stop="inputClick"
                                        >
                                            <font-awesome-icon  :icon="['fas', 'calendar-alt']" />
                                        </b-button>
                                    </b-input-group-prepend>
                                    <VueCtkDateTimePicker
                                        :id="'InvoiceDateRange'"
                                        class=""
                                        :range="true"
                                        :min-date="minDate"
                                        :max-date="maxDate"
                                        :disabled="false"
                                        v-model="reportRange"
                                        :only-date="true"
                                        :only-time="false"
                                        :format="`YYYY-MM-DD`"
                                        :formatted="dateFormat"
                                        :color="`#5a5aff`"
                                        :label="i18nT(`Select date`)"
                                        :button-now-translation="i18nT(`Today`)"
                                        :first-day-of-week="1"
                                        :no-button="true"
                                        :auto-close="true"
                                        :locale="localeCtk"
                                        :ref="`invoiceRangePicker`"
                                    />
                                </b-input-group>

                            </b-form-group>
                        </b-col>

                        <!-- VENDORS FILTER -->
                        <b-col
                            cols="12"
                            md="3"
                        >
                            <b-form-group
                                :label="i18nT(`Vendors`)"
                                label-for="assignee"
                                v-if="controlVisible"
                            >
                                <v-select
                                    id="assignee"
                                    v-model="vendor"
                                    :options="vendors"
                                    :clearable="false"
                                    label="text"
                                />
                            </b-form-group>
                        </b-col>


                        <!-- Record TYPE -->
                        <b-col
                            cols="12"
                            md="3"
                            v-if="controlVisible"
                        >
                            <b-form-group
                                :label="i18nT(`Category`)"
                                label-for="documentGroup"
                            >
                                <v-select
                                    id="documentGroup"
                                    v-model="category"
                                    :options="paymentCategoryList"
                                    :clearable="false"
                                    label="text"
                                    :selectable="selected => selected.disabled !== true"
                                />
                            </b-form-group>
                        </b-col>

                        <b-col
                            cols="12"
                            md="3"
                        >
                            <b-form-group
                                :label="i18nT(`Payment method`)"
                                label-for="paymentMethod"
                                v-if="controlVisible"
                            >
                                <v-select
                                    id="paymentMethod"
                                    v-model="paymentType"
                                    :options="paymentTypes"
                                    :clearable="false"
                                    label="text"
                                />
                            </b-form-group>
                        </b-col>


                        <!-- INVOICE TYPE -->
                        <b-col
                            cols="12"
                            md="3"
                        >
                            <b-form-group
                                :label="i18nT(`Type`)"
                                label-for="type"
                                v-if="controlVisible"
                            >
                                <v-select
                                    id="type"
                                    v-model="invoiceType"
                                    :options="invoiceTypes"
                                    :clearable="false"
                                    label="text"
                                />
                            </b-form-group>
                        </b-col>

                    </b-row>
                </div>
            </div>

            <div>
                <div>
                    <div class="row mx-2">
                        <div class="col-12 col-md-6 col-sm 6 mb-1 pl-0">
                            <v-select
                                v-model="perPage"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="perPageOptions"
                                :clearable="false"
                                class="per-page-selector d-inline-block"
                            />
                            <b-dropdown
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                text="Actions"
                                size="md"
                                class="d-inline ml-1"
                                variant="outline-secondary"
                                v-if="selectedItems.length > 0"
                            >
                                <!-- <b-dropdown-item
                                    v-if="iCan( 'payments', 'write' )"
                                    @click="onBulkCopy">
                                    <feather-icon icon="CopyIcon"/>
                                    <span class="align-middle ml-50">{{ i18nT(`Copy`) }}</span>
                                </b-dropdown-item> -->
                                <b-dropdown-item
                                    v-if="iCan( 'payments', 'write' )"
                                    @click="onBulkDelete">
                                    <feather-icon
                                        icon="TrashIcon"
                                        color="red"
                                    />
                                    <span
                                        class="align-middle ml-50"
                                        style="color: red"
                                    >{{ i18nT(`Delete`) }}</span>
                                </b-dropdown-item>
                            </b-dropdown>
                            <!-- <b-dropdown
                              size="md"
                              class="d-inline ml-1"
                              variant="outline-secondary"
                            >
                              <template v-slot:button-content>
                                <feather-icon icon="ExternalLinkIcon" />&nbsp;&nbsp;{{i18nT(`Export`)}}
                              </template>
                              <b-dropdown-item @click="onExportExcel()">
                                <feather-icon icon="CopyIcon" />
                                <span class="align-middle ml-50">{{i18nT(`Excel`)}}</span>
                              </b-dropdown-item>
                              <b-dropdown-item @click="onExportCsv()">
                                <feather-icon icon="CopyIcon" />
                                <span class="align-middle ml-50">{{i18nT(`CSV`)}}</span>
                              </b-dropdown-item>
                            </b-dropdown> -->
                        </div>
                        <!-- search input -->
                        <div class="col-12 col-md-6 col-sm 6 mb-1 pr-0">
                            <div class="custom-search d-flex justify-content-end">
                                <b-form-group>
                                    <div
                                        role="group"
                                        class="input-group input-group-merge"
                                    >
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <feather-icon icon="SearchIcon"/>
                                            </div>
                                        </div>
                                        <b-form-input
                                            v-model.lazy="searchQuery"
                                            :placeholder="i18nT(`Search`)"
                                            type="text"
                                            class="d-inline-block"
                                        />
                                    </div>
                                </b-form-group>
                            </div>
                        </div>
                    </div>
                    <b-table
                        ref="refDocumentListTable"
                        :items="fetchDocuments"
                        responsive
                        :fields="computedColumns"
                        primary-key="id"
                        :sort-by.sync="sortBy"
                        show-empty
                        :empty-html="emptyHtml"
                        :sort-desc.sync="isSortDirDesc"
                        class="position-relative"
                        @refreshed="onTableRefresh"
                        v-model="currentItems"
                    >

                        <template v-slot:head(checkbox)="data">
                            <span>{{ data.label.toUpperCase() }}</span>
                            <b-form-checkbox
                                class="d-inline-block pl-0"
                                @change="onSelectAll"
                                v-model="selectAllStatus"
                            >
                            </b-form-checkbox>
                        </template>

                        <template #head(action)="data">
                            <span>{{ data.label.toUpperCase() }}</span>
                            <div class="sk-column-control">
                                <b-dropdown
                                    no-caret
                                    variant="button"
                                    toggle-class="p-0"
                                    :right="true"
                                    class="pr-1 pl-1 d-flex sk-column-filter"
                                    v-if="betaFeature"
                                    v-b-tooltip = "i18nT(`Customize columns`)"
                                >
                                    <template #button-content>
                                        <feather-icon
                                            icon="SettingsIcon"
                                            size="18"
                                            class="text-right"
                                        />
                                    </template>

                                    <div class="pl-1 pr-1" v-for="(field, index) in avalableFields" :key="'Fields - ' + index">
                                        <b-form-checkbox class="mb-1 mt-1 text-nowrap" v-model="field.visible">{{ i18nT(field.label) }}</b-form-checkbox>
                                    </div>
                                </b-dropdown>
                            </div>
                        </template>

                        <template #cell(checkbox)="data">
                            <b-form-checkbox
                                v-model="selectedItems"
                                :value="data.item.Id"
                            />
                        </template>


                        <template #cell(type)="data">
                            <span class="cursor-pointer d-inline-block text-primary mr-1"
                                      @click="previewInModal(data)"
                                      v-b-tooltip="data.item.RecordType == 3 ? i18nT(`Preview credit note`) : i18nT(`Preview invoice`)">

                                    <font-awesome-icon
                                        v-if="data.item.RecordType == 3"
                                        class="mr-25" :icon="['fas', 'exchange-alt']" />

                                    <font-awesome-icon
                                        v-else
                                        class="mr-25" :icon="['fas', 'file-invoice-dollar']" />

                                </span>
                                <b-link
                                    :class="data.item.PaidStatus == 'void' ? 'opacity-08' : ''"
                                    :to="{
                                        name: editScreenName(data.item),
                                        params: { id: data.item.Id } }
                                    "
                                   >
                                    <span v-if="data.item.Prefix">{{ data.item.Prefix}}-{{ data.item.InvoiceNumber }}</span>
                                    <span v-else>{{ data.item.InvoiceNumber }}</span>
                                    <p class="font-small-2 mb-0 text-primary">{{ getCategoryName(data.item.Category) }}</p>
                                </b-link>
                        </template>

                        <template #cell(vendor)="data">
                            <span v-if="data.item.VendorObj">{{ data.item.VendorObj.Label }}</span>
                        </template>

                        <template #cell(total_amount)="data">
                            <span :class="data.item.Type == 1 ? 'text-success' : 'text-danger'">
                                {{data.item.Type == 1 ? '+' : '-'}}{{ {amount: data.item.TotalAmount, currency: data.item.Currency } | currency }}
                            </span>
                        </template>

                        <template #cell(invoice_date)="data">
                            {{ data.item.InvoiceDate | date }}
                        </template>

                        <template #cell(created)="data">
                            {{ data.item.CreatedAt | date }}
                        </template>


                        <template #cell(PaidStatus)="data">
                            <b-badge
                                :variant="statusLabelClass(data.item.PaidStatus)"
                                class="mr-1 badge-pill">
                                {{statusLabel(data.item.PaidStatus)}}
                            </b-badge>
                        </template>


                        <template #cell(action)="data">

                            <b-dropdown
                                v-if="data.item.PublicationStatus !== 'SYSTEM'"
                                variant="link"
                                toggle-class="p-0"
                                no-caret
                                :right="$store.state.appConfig.isRTL"
                            >

                                <template #button-content>
                                    <feather-icon
                                        icon="MoreVerticalIcon"
                                        size="16"
                                        class="align-middle text-body"
                                    />
                                </template>
                                <b-dropdown-item
                                :to="{
                                        name: editScreenName(data.item),
                                        params: { id: data.item.Id } }
                                    ">
                                    <feather-icon icon="EditIcon"/>
                                    <span class="align-middle ml-50">{{ i18nT(`Edit`) }}</span>
                                </b-dropdown-item>
                                

                                <b-dropdown-item
                                    v-if="data.item.PaidStatus !== 'paid' || data.item.PaidStatus !== 'overpaid'"
                                    @click="setPaymentInvoiceObj(data.item)">
                                    <feather-icon icon="PlusIcon"/>
                                    <span class="align-middle ml-50">{{ i18nT(`Record payment`) }}</span>
                                </b-dropdown-item>
                                <b-dropdown-item @click="onCopy(data)">
                                  <feather-icon icon="CopyIcon" />
                                  <span class="align-middle ml-50">{{i18nT(`Copy`)}}</span>
                                </b-dropdown-item>
                                <b-dropdown-item
                                    @click="onDelete(data)">
                                    <feather-icon
                                        icon="TrashIcon"
                                        color="red"
                                    />
                                    <span
                                        class="align-middle ml-50"
                                        style="color: red"
                                    >{{ i18nT(`Delete`) }}</span>
                                </b-dropdown-item>
                            </b-dropdown>
                        </template>
                    </b-table>

                </div>
                <!-- Disable grid view -->
                <b-tabs
                    pills
                    align="center"
                    v-if="false"
                >
                    <b-tab active>
                        <template #title>
                            <feather-icon
                                icon="ListIcon"
                                size="24"
                                style="margin: 0;"
                            />
                        </template>

                    </b-tab>

                    <b-tab>
                        <template
                            #title
                            style="text-align: center"
                        >
                            <feather-icon
                                icon="GridIcon"
                                size="24"
                                style="margin: 0;"
                            />
                        </template>
                        <div class="task-container">
                            <b-row class="task-item">
                                <b-col
                                    cols="3"
                                    v-for="document in documents"
                                    :key="document.Id"
                                >
                                    <b-card style="border: 1px solid rgba(0, 0, 0, 0.07);">
                                        <b-card-body class="p-0">

                                            <p> {{ getTypeName(document.Type) }}</p>

                                            <h3 style="height: 50px;">{{ document.Label }}</h3>
                                            <p
                                                style="height: 45px; overflow: hidden;"
                                                v-html="document.Description"
                                            ></p>
                                            <b-list-group
                                                class="list-group-messages"
                                                style="height: 200px"
                                            >
                                                <b-list-group-item
                                                    class="p-0 border-0 line-item"
                                                    v-if="document.CreatedAt"
                                                >
                                                    <feather-icon
                                                        icon="CalendarIcon"
                                                        size="18"
                                                        class="mr-75"
                                                    />
                                                    <span
                                                        class="align-text-bottom line-height-1">Created {{ document.CreatedAt | date }}</span>
                                                </b-list-group-item>
                                                <b-list-group-item
                                                    class="p-0 border-0 line-item"
                                                    v-if="document.UpdatedAt"
                                                >
                                                    <feather-icon
                                                        icon="CheckCircleIcon"
                                                        size="18"
                                                        class="mr-75"
                                                    />
                                                    <span
                                                        class="align-text-bottom line-height-1">Last update {{ document.UpdatedAt | date }}</span>
                                                </b-list-group-item>
                                                <b-list-group-item
                                                    class="p-0 border-0 line-item"
                                                    v-if="document.DocumentDueDate"
                                                >
                                                    <feather-icon
                                                        icon="CastIcon"
                                                        size="18"
                                                        class="mr-75"
                                                    />
                                                    <span class="align-text-bottom line-height-1">Due {{
                                                            document.DocumentDueDate
                                                        }}</span>
                                                </b-list-group-item>
                                                <b-list-group-item
                                                    class="p-0 border-0 line-item"
                                                    v-if="document.DocumentGroups"
                                                >
                                                    <feather-icon
                                                        icon="ListIcon"
                                                        size="18"
                                                        class="mr-75"
                                                    />
                                                    <span class="align-text-bottom line-height-1">
                                                        <span v-html="document.DocumentGroups"/>
                                                    </span>
                                                </b-list-group-item>
                                                <div v-if="document.AssignedTo">
                                                    <p class="mt-2">
                                                        {{ i18nT(`Assigned to`) }}
                                                    </p>
                                                    <b-list-group-item class="p-0 border-0">
                                                        <b-avatar
                                                            class="mr-1"
                                                            size="32"
                                                            :text="getAvatarName(getAssigneeName(document.AssignedTo))"
                                                            variant="light-success"
                                                        >
                                                        </b-avatar>
                                                        <span class="align-text-bottom line-height-1">{{
                                                                getAssigneeName(document.AssignedTo)
                                                            }}</span>
                                                    </b-list-group-item>
                                                </div>
                                            </b-list-group>
                                            <div>
                                            </div>

                                        </b-card-body>

                                        <b-card-footer class="pb-0 pt-1 pr-1 pl-1 mt-2 ml-n2 mr-n2 text-top text-right">
                                            <b-dropdown
                                                variant="link"
                                                toggle-class="p-0"
                                                no-caret
                                            >

                                                <template #button-content>
                                                    <b-button>
                                                        {{i18nT(`Actions`)}}
                                                    </b-button>
                                                </template>
                                                <b-dropdown-item
                                                    :to="{ name: 'edit-finance-invoice', params: { id: document.Id } }">
                                                    <feather-icon icon="EditIcon"/>
                                                    <span class="align-middle ml-50">{{ i18nT(`Edit`) }}</span>
                                                </b-dropdown-item>
                                                <b-dropdown-item @click="onCopy({item: document})">
                                                    <feather-icon icon="CopyIcon"/>
                                                    <span class="align-middle ml-50">{{ i18nT(`Copy`) }}</span>
                                                </b-dropdown-item>
                                                <b-dropdown-item @click="onDelete({item: document})">
                                                    <feather-icon
                                                        icon="TrashIcon"
                                                        color="red"
                                                    />
                                                    <span
                                                        class="align-middle ml-50"
                                                        style="color: red"
                                                    >{{ i18nT(`Delete`) }}</span>
                                                </b-dropdown-item>
                                            </b-dropdown>
                                        </b-card-footer>
                                    </b-card>
                                </b-col>
                            </b-row>
                        </div>
                    </b-tab>
                </b-tabs>

                <div class="mx-1 mb-2">
                    <b-row>
                        <!-- <b-col> -->
                        <!-- </b-col> -->
                        <b-col
                            cols="12"
                            sm="6"
                            class="d-flex align-items-center justify-content-center justify-content-sm-start"
                        >
                            <b-form-checkbox
                                class="d-inline-block mx-1"
                                @change="onSelectAll"
                                v-model="selectAllStatus"
                            >
                                {{ i18nT(`All`) }}
                            </b-form-checkbox>
                            <span class="text-muted">{{ i18nT(`Showing`) }} {{
                                    dataMeta.from
                                }} {{ i18nT(`to`) }} {{ dataMeta.to }} {{ i18nT(`of`) }} {{
                                    dataMeta.of
                                }} {{ i18nT(`entries`) }}</span>
                        </b-col>
                        <!-- Pagination -->
                        <b-col
                            cols="12"
                            sm="6"
                            class="d-flex align-items-center justify-content-center justify-content-sm-end"
                        >
                            <b-pagination
                                v-model="currentPage"
                                :total-rows="totalDocuments"
                                :per-page="perPage"
                                first-number
                                last-number
                                class="mb-0 mt-1 mt-sm-0"
                                prev-class="prev-item"
                                next-class="next-item"
                            >
                                <template #prev-text>
                                    <feather-icon
                                        icon="ChevronLeftIcon"
                                        size="18"
                                    />
                                </template>
                                <template #next-text>
                                    <feather-icon
                                        icon="ChevronRightIcon"
                                        size="18"
                                    />
                                </template>
                            </b-pagination>

                        </b-col>

                    </b-row>
                </div>
            </div>
        </b-card>
        <invoice-sidebar-add-payment
            :visible="isAddPaymentVisible"
            :invoice-id="invoiceData.Id"
            :due-amount="invoiceData.DueAmount ? parseFloat(invoiceData.DueAmount) : 0"
            :currency="invoiceData.Currency ? invoiceData.Currency : 'EUR'"
            :cur-locale="locale"
            :invoice-status="invoiceData.PaidStatus"
            @updated="refetchInvoiceData"
            @close="closeAddPayment"
        />

        <b-modal
            id="preview-invoice-modal"
            title="Invoice Preview"
            class="overflow-y-scroll"
            hide-footer
            size="lg"
            centered
            :hide-header="true"
        >
            <div class="frame-container">
                <h4 class="text-center py-2">{{i18nT(`Invoice preview`)}}</h4>
                <iframe
                    :src="previewIframeUrl+'&lang='+locale"
                    width="100%"
                    height="100%"
                    frameborder="0"
                ></iframe>

                <div id="frame-spinner"
                     class="position-absolute
                                    w-100
                                    d-flex justify-content-center
                                    align-items-center align-content-center">
                    <b-spinner label="Loading..."
                               variant="primary"
                               class="mr-2"
                               color="primary"></b-spinner>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import {
    BInputGroup,
    BInputGroupPrepend,
    BLink,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BTable,
    BAvatar,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTabs,
    BTab,
    BListGroup,
    BListGroupItem,
    BCardFooter,
    BCardBody,
    BFormCheckbox,
    BFormInput,
    VBTooltip,
    BBadge, BSpinner
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import useFinanceList from './useFinanceList'
import {avatarText} from '@core/utils/filter'
import {dictToSelectArray} from '@core/utils/utils'
import router from '@/router'
import useAuth from '@/auth/useAuth'
import Ripple from 'vue-ripple-directive'
import InvoiceSidebarAddPayment from "@/views/apps/invoice/InvoiceSidebarAddPayment.vue";
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
import moment from "moment/moment";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";


export default {
    components: {
        BSpinner,
        BInputGroup,
        BInputGroupPrepend,
        BLink,
        BRow,
        BCol,
        BFormGroup,
        BCard,
        BButton,
        BTable,
        BAvatar,
        BDropdown,
        BDropdownItem,
        BPagination,
        BTabs,
        BTab,
        BListGroup,
        BListGroupItem,
        BCardFooter,
        BCardBody,
        vSelect,
        BFormCheckbox,
        BFormInput,
        BBadge,
        InvoiceSidebarAddPayment,
        VueCtkDateTimePicker

    },
    directives: {
        Ripple,
        'b-tooltip': VBTooltip
    },
    props: {
        selectedFinanceType: {
            type: String
        }
    },
    data() {

        return {
            locale: this.$i18n.locale,
            invoiceData: {},
            isAddPaymentVisible: false,
            reportRange: {
                start: '',
            },
            previewIframeUrl: null,

            invoiceTypes: [],
            paymentCategoryList: [],
            vendors: [],
            paymentTypes: [],
            pagination: {},
            selectedItems: [],
            currentItems: [],
            selectAllStatus: false,
            avalableFields: [
                {label: this.i18nT(`Description`), key: 'type', visible: true},
                {label: this.i18nT(`Date`), key: 'invoice_date', visible: true},
                {label: this.i18nT(`Amount`), key: 'total_amount', visible: true},
                {label: this.i18nT(`Vendor`), key: 'vendor', visible: true},
                {label: this.i18nT(`Status`), key: 'PaidStatus', visible: true},
            ],
            documentFilters: [
                {value: '', text: this.i18nT(`All`)},
                {value: 'created', text: this.i18nT(`Created by me`)},
                {value: 'shared', text: this.i18nT(`Shared by me`)}
            ],
            controlVisible: true,
        }
    },
    mounted() {
        if(this.isInvoiceList) {
            this.invoiceType = {text: this.i18nT(`Income`), value: '1'};
        }
    },
    created() {

        let storePreferences = useAuth.getColumnPreferences('FinanceTemplates');
          if(storePreferences) {
            this.avalableFields = storePreferences
        }

        this.reportRange.start = moment().subtract(3, 'months').startOf('month').format('YYYY-MM-DD');
        this.$http.get(`system/financeTypes`).then(({data}) => {
            this.invoiceTypes = dictToSelectArray(data.data)
            this.invoiceTypes.unshift({
                value: '-1',
                text: this.i18nT(`All`)
            })
        })
        this.$http.get(`invoices/categories`).then(({data}) => {
            const extendetOptionsIncome = data.data.filter(incomeOpt => incomeOpt.Type === 1)
            const extendetOptionsExpense = data.data.filter(expenseOpt => expenseOpt.Type === 2)

            extendetOptionsIncome.unshift({
                    Id: 0,
                    Label: this.i18nT(`-- Income --`),
                    disabled: true
            })
            
            extendetOptionsExpense.unshift({
                    Id: 0,
                    Label: this.i18nT(`-- Expense -- `),
                    disabled: true
            })

            // this.paymentCategoryList = dictToSelectArray(data.data)
            this.paymentCategoryList = [...extendetOptionsIncome, ...extendetOptionsExpense].map(category => {
                return {
                    value: category.Id,
                    text: category.Label,
                    fullData: category,
                    disabled: category.disabled ? true : false
                }
            })

            this.paymentCategoryList.unshift({
                value: '-1',
                text: this.i18nT(`Any`)
            })
        })


        let filterString = '';
        if(this.isInvoiceList){
            filterString = '?invoicing=1';
        }

        this.$http.get(`vendors/vendorsList`+filterString).then(({data}) => {
            this.vendors = dictToSelectArray(data.data)
            this.vendors.unshift({
                value: -1,
                text: this.i18nT(`All`)
            })
        })
        this.$http.get(`system/paymentTypes`).then(({data}) => {
            this.paymentTypes = dictToSelectArray(data.data)
            this.paymentTypes.unshift({
                value: -1,
                text: this.i18nT(`All`)
            })
        })

        this.fetchDocuments(null, this.determineVisibility);
    },
    methods: {
        previewInModal(data) {
            this.previewIframeUrl = data.item.IframePublicUrl
            this.$bvModal.show('preview-invoice-modal')
        },
        determineVisibility(invoiceArray) {
            if(invoiceArray.length===0){
              this.controlVisible = false;
              this.$emit('update:controlVisible', false);
            }
        },
        inputClick() {
            const inputElement = this.$refs[`invoiceRangePicker`].$el.querySelector('input');
            if (inputElement) {
                inputElement.click();
            }
        },
        editScreenName(item) {
            if(item.RecordType === 1){
                return 'edit-invoice'
            } else {
                if (item.Type == 1) {
                    return 'edit-income'
                } else {
                    return 'edit-expense'
                }
            }
        },
        setPaymentInvoiceObj(invoiceObj) {
            this.invoiceData = invoiceObj;
            this.openAddPaynmentModal();
        },
        openAddPaynmentModal() {
            if(this.isAddPaymentVisible) {
                this.isAddPaymentVisible = false;
            }
            setTimeout(() => {
                this.isAddPaymentVisible = true;
            }, 0);
        },
        closeAddPayment() {
            this.isAddPaymentVisible = false;
        },
        statusLabel(status){
            return {
                'paid': this.i18nT(`Paid`),
                'partial': this.i18nT(`Partial`),
                'not_paid': this.i18nT(`Not paid`),
                'overpaid': this.i18nT(`Overpaid`),
                'overdue': this.i18nT(`Overdue`),
                'void': this.i18nT(`Void`)
            }[status]
        },
        statusLabelClass(status) {
            return {
                'paid': 'success',
                'partial': 'warning',
                'not_paid': 'danger',
                'overdue': 'dark-danger',
                'overpaid': 'info',
                'void': 'dark'
            }[status]
        },
        getCategoryName(typeId) {
            const invoiceType = this.paymentCategoryList.find(type => type.value == typeId)
            return invoiceType ? invoiceType.text : ''
        },
        getTypeName(typeId) {
            const invoiceType = this.invoiceTypes.find(type => type.value == typeId)
            return invoiceType ? invoiceType.text : ''
        },
        getAssigneeName(assignee) {
            const assigneeList = Object.values(assignee)
            return assigneeList.map(ass => ass.Label).join(', ')
        },
        getAvatarName(name) {
            if (name) {
                return name
                    .split(' ')
                    .map(namePart => {
                        return namePart[0]
                    })
                    .join('')
                    .toUpperCase()
            } else {
                return ''
            }
        },
        onTableRefresh() {
            this.selectedItems = []
        },
        onCopy(element) {
            this.$http
                .post(`invoices/copy?invoice_id=${element.item.Id}`)
                .then(() => {
                    this.refetchData()
                })
        },
        refetchInvoiceData(){
            this.refetchData();
        },
        onDelete(task) {
            this.$swal({
                title: this.i18nT(`Are you sure?`),
                text: this.i18nT(`You won't be able to revert this!`),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: this.i18nT(`Yes, delete it!`),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1'
                },
                buttonsStyling: false
            }).then(result => {
                if (result.value) {
                    this.$http.delete(`invoices?id=${task.item.Id}`).then(() => {
                        this.$swal({
                            icon: 'success',
                            title: this.i18nT(`Deleted!`),
                            text: this.i18nT(`The document has been deleted.`),
                            customClass: {
                                confirmButton: 'btn btn-success'
                            }
                        })
                        this.refetchData()
                    }).catch(err => {
                        this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: this.errorMessage(err),
                                icon: 'AlertTriangleIcon',
                                variant: 'danger'
                            }
                        })
                    })
                }
            })
        },
        onBulkCopy() {
            this.$http
                .post(
                    `invoices/copy?invoice_id=${this.selectedItems.join(',')}`
                )
                .then(() => {
                    this.selectedItems = []
                    this.selectAllStatus = false
                    this.refetchData()
                })
        },
        onBulkDelete() {
            this.$swal({
                title: this.i18nT(`Are you sure?`),
                text: this.i18nT(`You won't be able to revert this!`),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: this.i18nT(`Yes, delete it!`),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1'
                },
                buttonsStyling: false
            }).then(result => {
                if (result.value) {
                    this.$http
                        .delete(`invoices?id=${this.selectedItems.join(',')}`)
                        .then(() => {
                            this.$swal({
                                icon: 'success',
                                title: this.i18nT(`Deleted!`),
                                text:  this.i18nT(`Selected invoice(s) were deleted!`),
                                customClass: {
                                    confirmButton: 'btn btn-success'
                                }
                            })
                            this.selectedItems = []
                            this.selectAllStatus = false
                            this.refetchData()
                        })
                        .catch(err => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.errorMessage(err),
                                    icon: 'AlertTriangleIcon',
                                    variant: 'danger'
                                }
                            })
                        })
                }
            })
        },
        onSelectAll(val) {
            if (val) {
                this.selectedItems = this.currentItems.map(item => item.Id)
            } else {
                this.selectedItems = []
            }
        },
        onExportExcel() {
        },
        onExportCsv() {
        }
    },
    watch: {
        reportRange(newRange) {

            if(newRange) {
                if(newRange.start) {
                    this.startDate = moment(newRange.start).format('YYYY-MM-DD');
                }
                if (newRange.end) {
                    this.endDate = moment(newRange.end).format('YYYY-MM-DD');
                }
            } else {
                this.startDate = '';
                this.endDate = '';
            }

        },
        selectedFinanceType: {
            handler: function (newDocType, oldDocType) {

                if(oldDocType != newDocType){
                    if (newDocType == 1 || newDocType == 2) {
                        this.$http.get(
                            `invoices/categories?type=`+newDocType
                        ).then(({data}) => {
                            this.paymentCategoryList = data.data.map(category => {
                                return {
                                    value: category.Id,
                                    text: category.Label,
                                    fullData: category
                                }
                            })
                            this.paymentCategoryList.unshift({
                                value: '-1',
                                text: this.i18nT(`All`)
                            })
                            this.category = {value: '-1', text: this.i18nT(`All`)};

                        })
                    } else {
                        this.$http.get(
                            `invoices/categories`
                        ).then(({data}) => {
                            this.paymentCategoryList = data.data.map(category => {
                                return {
                                    value: category.Id,
                                    text: category.Label,
                                    fullData: category
                                }
                            })
                            this.paymentCategoryList.unshift({
                                value: '-1',
                                text: this.i18nT(`Any`)
                            })
                            this.category = {value: '-1', text: this.i18nT(`Any`)};
                        })
                    }
                }

            }
        },
        invoiceType(newVal) {
            if (newVal.value === '-1') {
                this.selectedFinanceType = ''
            } else {
                this.selectedFinanceType = newVal.value
            }
        }
    },
    setup() {
        // const statusOptions = [
        //   'Downloaded',
        //   'Draft',
        //   'Paid',
        //   'Partial Payment',
        //   'Past Due',
        // ]

        let isInvoiceList = router.currentRoute.name === 'invoices'

        const {
            fetchDocuments,
            tableColumns,
            perPage,
            currentPage,
            totalDocuments,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refDocumentListTable,
            statusFilter,
            invoiceType,
            category,
            assignedTo,
            vendor,
            paymentType,
            documents,
            documentFilter,
            startDate,
            endDate,
            refetchData
        } = useFinanceList(router.currentRoute.meta.template === 1)

        return {
            fetchDocuments,
            tableColumns,
            perPage,
            currentPage,
            totalDocuments,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refDocumentListTable,
            statusFilter,
            invoiceType,
            category,
            assignedTo,
            vendor,
            paymentType,
            startDate,
            endDate,
            avatarText,
            documents,
            documentFilter,
            isInvoiceList,
            refetchData
        }
    },
    computed: {
        computedColumns() {
            let col = [...this.tableColumns]
            for(let i = 0; i < this.avalableFields.length; i++) {
              if(!this.avalableFields[i].visible) {
                col = col.filter(c => c.key !== this.avalableFields[i].key)
              }
            }
            useAuth.setColumnPreferences('FinanceTemplates', this.avalableFields);
            return col
        },
        dateFormat() {
            return useAuth.getDateFormat() !== 'undefined'
                ? useAuth.getDateFormat()
                : 'DD/MM/YYYY'
        },
        localeCtk() {
            return useAuth.getLocale() !== 'undefined'
                ? useAuth.getLocale()
                : 'en'
        },
        minDate() {
            return moment('2022-01-01').format();
        },
        maxDate() {
            return moment().format();
        },
        // emptyHtml() {
        //     return "<img src='" + require('@/assets/images/pages/error.svg') + "' alt='Error page' class='img-fluid' width='300'><br/><br/><h4>" + this.i18nT(`No matching records found`) + "</h4>";
        // },
        emptyHtml() {
          return "<img src='" + require('@/assets/images/pages/error.svg') + "' alt='Error page' class='img-fluid' width='300'><br/><br/><h4>"
              + this.i18nT(`Here, you can create and manage your finances. No invoices have been created yet.`) + "</h4>"
              +"<button class='btn btn-outline-secondary mt-2'  onclick='window.location.href=\"/ac/invoices/create/1\"'>" + this.i18nT(`Create invoice`) + "</button>"
        },
    }

}
</script>

<style lang="scss">


#preview-invoice-modal.modal .modal-content {
    overflow: hidden;
    overflow-y: scroll;
}


.frame-container {
    position: relative;
    width: 100%;
    height: 820px;
    min-height: 720px;
}

.frame-container iframe {
    width: 100%;
    height: 100%;
    border: none;
    z-index: 20;
    position: relative;
}

#frame-spinner {
    display: block;
    z-index: 2;
    top: 50%;
}

div.date-time-picker {
    width: calc(100% - 56px);
}

.date-time-picker input.field-input {
    padding-right: 5px;
}

.ctk-date-time-picker__header,
.datetimepicker .custom-button,
.datetimepicker span.custom-button-content,
.datepicker-buttons-container .datepicker-button.now .datepicker-button-content
{
    /* Change the header text color */
    color: #5a5aff !important;
    fill: #5a5aff !important;
}

.task-container {
    .task-item {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .line-item {
        margin-top: 4px;
    }
}
</style>
