<script src="useFinanceList.js"></script>
<template>
    <div>
        <b-card no-body>
            <div class="m-2">

                <b-row>
                    <b-col cols="12">
                        <h1 class="float-left">{{ i18nT(`Recorded payments`) }}</h1>
                    </b-col>
                </b-row>

                <div class="mb-1">
                    <span class="text-muted">{{ i18nT(`Showing`) }} {{
                        dataMeta.from
                    }} {{ i18nT(`to`) }} {{ dataMeta.to }} {{ i18nT(`of`) }} {{
                        dataMeta.of
                    }} {{ i18nT(`entries`) }}</span>
                </div>

                <b-row>
                    <!-- INVOICE TYPE -->
                    <b-col
                        cols="12"
                        md="3"
                    >
                        <b-form-group
                            :label="i18nT(`Type`)"
                            label-for="type"
                        >
                            <v-select
                                id="type"
                                v-model="invoiceType"
                                :options="invoiceTypes"
                                :clearable="false"
                                label="text"
                            />
                        </b-form-group>
                    </b-col>

                    <!-- Record TYPE -->
                    <b-col
                        cols="12"
                        md="3"
                    >
                        <b-form-group
                            :label="i18nT(`Document category`)"
                            label-for="documentGroup"
                        >
                            <v-select
                                id="documentGroup"
                                v-model="category"
                                :options="paymentCategoryList"
                                :clearable="false"
                                label="text"
                            />
                        </b-form-group>
                    </b-col>

                    <b-col
                        cols="12"
                        md="3"
                    >
                        <b-form-group
                            :label="i18nT(`Payment method`)"
                            label-for="paymentType"
                        >
                            <v-select
                                id="software"
                                v-model="paymentType"
                                :options="paymentTypes"
                                :clearable="false"
                                label="text"
                            />
                        </b-form-group>
                    </b-col>

                    <b-col
                        cols="12"
                        md="3"
                        v-if="displayExtentControls"
                    >
                        <b-form-group
                            :label="i18nT(`Currency`)"
                            label-for="currency"
                        >
                            <v-select
                                id="currency"
                                v-model="currency"
                                :options="currencies"
                                :clearable="false"
                                label="text"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
            </div>


            <div>
                <div>

                    <b-table
                        ref="refDocumentListTable"
                        :items="fetchDocuments"
                        responsive
                        :fields="computedColumns"
                        primary-key="id"
                        :sort-by.sync="sortBy"
                        show-empty
                        :empty-html="emptyHtml"
                        :sort-desc.sync="isSortDirDesc"
                        class="position-relative"
                        @refreshed="onTableRefresh"
                        v-model="currentItems"
                    >

                        <template v-slot:head(checkbox)="data">
                            <span>{{ data.label.toUpperCase() }}</span>
                            <b-form-checkbox
                                class="d-inline-block pl-0"
                                @change="onSelectAll"
                                v-model="selectAllStatus"
                            >
                            </b-form-checkbox>
                        </template>

                        <template #head(action)="data">
                            <span>{{ data.label.toUpperCase() }}</span>
                            <div class="sk-column-control">
                                <b-dropdown
                                    no-caret
                                    variant="button"
                                    toggle-class="p-0"
                                    :right="true"
                                    class="pr-1 pl-1 d-flex sk-column-filter"
                                    v-if="betaFeature"
                                    v-b-tooltip = "i18nT(`Customize columns`)"
                                >
                                    <template #button-content>
                                        <feather-icon
                                            icon="SettingsIcon"
                                            size="18"
                                            class="text-right"
                                        />
                                    </template>

                                    <div class="pl-1 pr-1" v-for="(field, index) in avalableFields" :key="'Fields - ' + index">
                                        <b-form-checkbox class="mb-1 mt-1 text-nowrap" v-model="field.visible">{{ i18nT(field.label) }}</b-form-checkbox>
                                    </div>
                                </b-dropdown>
                            </div>
                        </template>

                        <template #cell(checkbox)="data">
                            <b-form-checkbox
                                v-model="selectedItems"
                                :value="data.item.Id"
                            />
                        </template>

                        <template #cell(CreatedAt)="data">
                            {{ data.item.CreatedAt | date }}
                        </template>


                        <template #cell(Amount)="data">
                            <span :class="data.item.Type == 1 ? 'text-success' : 'text-danger'">
                                {{data.item.Type == 1 ? '+' : '-'}}{{ {amount: data.item.Amount, currency: data.item.Currency } | currency }}
                            </span>
                        </template>


                        <template #cell(PaymentType)="data">
                            <font-awesome-icon
                                :icon="['fas', paymentTypeIcon(data.item.PaymentType)]"
                                class="mr-1"
                            />
                            <span v-if="PaymentTypeDict && PaymentTypeDict[data.item.PaymentType]"
                            >{{ PaymentTypeDict[data.item.PaymentType] }}</span>
                        </template>


                        <template #cell(invoice_obj)="data">
                          <div
                              class="text-truncate sk-document-label"
                              v-if="data.item.InvoiceObj"
                          >
                            <b-link :to="{
                                      name: 'finance-preview',
                                      params: { id: data.item.InvoiceObj.Id },
                                    }"
                                target="_blank"

                            >
                                {{ data.item.InvoiceObj ? data.item.InvoiceObj.Label : '' }}
                            </b-link>
                          </div>
                        </template>

                        <template #cell(action)="data">

                            <b-dropdown
                                v-if="data.item.PublicationStatus !== 'SYSTEM'"
                                variant="link"
                                toggle-class="p-0"
                                no-caret
                                :right="$store.state.appConfig.isRTL"
                            >

                                <template #button-content>
                                    <feather-icon
                                        icon="MoreVerticalIcon"
                                        size="16"
                                        class="align-middle text-body"
                                    />
                                </template>
<!--                                <b-dropdown-item :to="{ name: 'edit-invoice', params: { id: data.item.Id } }">-->
<!--                                    <feather-icon icon="EditIcon"/>-->
<!--                                    <span class="align-middle ml-50">{{ i18nT(`Edit`) }}</span>-->
<!--                                </b-dropdown-item>-->
                                <b-dropdown-item @click="setPaymentInvoiceObj(data.item)">
                                  <feather-icon icon="EditIcon" />
                                  <span class="align-middle ml-50">{{i18nT(`Edit`)}}</span>
                                </b-dropdown-item>
                                <b-dropdown-item @click="onDelete(data)">
                                    <feather-icon
                                        icon="TrashIcon"
                                        color="red"
                                    />
                                    <span
                                        class="align-middle ml-50"
                                        style="color: red"
                                    >{{ i18nT(`Delete`) }}</span>
                                </b-dropdown-item>
                            </b-dropdown>
                        </template>
                    </b-table>

                </div>
                <!-- Disable grid view -->
                <b-tabs
                    pills
                    align="center"
                    v-if="false"
                >
                    <b-tab active>
                        <template #title>
                            <feather-icon
                                icon="ListIcon"
                                size="24"
                                style="margin: 0;"
                            />
                        </template>

                    </b-tab>

                    <b-tab>
                        <template
                            #title
                            style="text-align: center"
                        >
                            <feather-icon
                                icon="GridIcon"
                                size="24"
                                style="margin: 0;"
                            />
                        </template>
                        <div class="task-container">
                            <b-row class="task-item">
                                <b-col
                                    cols="3"
                                    v-for="document in documents"
                                    :key="document.Id"
                                >
                                    <b-card style="border: 1px solid rgba(0, 0, 0, 0.07);">
                                        <b-card-body class="p-0">

                                            <p> {{ getTypeName(document.Type) }}</p>

                                            <h3 style="height: 50px;">{{ document.Label }}</h3>
                                            <p
                                                style="height: 45px; overflow: hidden;"
                                                v-html="document.Description"
                                            ></p>
                                            <b-list-group
                                                class="list-group-messages"
                                                style="height: 200px"
                                            >
                                                <b-list-group-item
                                                    class="p-0 border-0 line-item"
                                                    v-if="document.CreatedAt"
                                                >
                                                    <feather-icon
                                                        icon="CalendarIcon"
                                                        size="18"
                                                        class="mr-75"
                                                    />
                                                    <span
                                                        class="align-text-bottom line-height-1">Created {{ document.CreatedAt | date }}</span>
                                                </b-list-group-item>
                                                <b-list-group-item
                                                    class="p-0 border-0 line-item"
                                                    v-if="document.UpdatedAt"
                                                >
                                                    <feather-icon
                                                        icon="CheckCircleIcon"
                                                        size="18"
                                                        class="mr-75"
                                                    />
                                                    <span
                                                        class="align-text-bottom line-height-1">Last update {{ document.UpdatedAt | date }}</span>
                                                </b-list-group-item>
                                                <b-list-group-item
                                                    class="p-0 border-0 line-item"
                                                    v-if="document.DocumentDueDate"
                                                >
                                                    <feather-icon
                                                        icon="CastIcon"
                                                        size="18"
                                                        class="mr-75"
                                                    />
                                                    <span class="align-text-bottom line-height-1">Due {{
                                                            document.DocumentDueDate
                                                        }}</span>
                                                </b-list-group-item>
                                                <b-list-group-item
                                                    class="p-0 border-0 line-item"
                                                    v-if="document.DocumentGroups"
                                                >
                                                    <feather-icon
                                                        icon="ListIcon"
                                                        size="18"
                                                        class="mr-75"
                                                    />
                                                    <span class="align-text-bottom line-height-1">
                                                        <span v-html="document.DocumentGroups"/>
                                                    </span>
                                                </b-list-group-item>
                                                <div v-if="document.AssignedTo">
                                                    <p class="mt-2">
                                                        {{ i18nT(`Assigned to`) }}
                                                    </p>
                                                    <b-list-group-item class="p-0 border-0">
                                                        <b-avatar
                                                            class="mr-1"
                                                            size="32"
                                                            :text="getAvatarName(getAssigneeName(document.AssignedTo))"
                                                            variant="light-success"
                                                        >
                                                        </b-avatar>
                                                        <span class="align-text-bottom line-height-1">{{
                                                                getAssigneeName(document.AssignedTo)
                                                            }}</span>
                                                    </b-list-group-item>
                                                </div>
                                            </b-list-group>
                                            <div>
                                            </div>

                                        </b-card-body>

                                        <b-card-footer class="pb-0 pt-1 pr-1 pl-1 mt-2 ml-n2 mr-n2 text-top text-right">
                                            <b-dropdown
                                                variant="link"
                                                toggle-class="p-0"
                                                no-caret
                                            >

                                                <template #button-content>
                                                    <b-button>
                                                        Actions
                                                    </b-button>
                                                </template>
                                                <b-dropdown-item
                                                    :to="{ name: 'edit-invoice', params: { id: document.Id } }">
                                                    <feather-icon icon="EditIcon"/>
                                                    <span class="align-middle ml-50">{{ i18nT(`Edit`) }}</span>
                                                </b-dropdown-item>
                                                <b-dropdown-item @click="onCopy({item: document})">
                                                    <feather-icon icon="CopyIcon"/>
                                                    <span class="align-middle ml-50">{{ i18nT(`Copy`) }}</span>
                                                </b-dropdown-item>
                                                <b-dropdown-item @click="onDelete({item: document})">
                                                    <feather-icon
                                                        icon="TrashIcon"
                                                        color="red"
                                                    />
                                                    <span
                                                        class="align-middle ml-50"
                                                        style="color: red"
                                                    >{{ i18nT(`Delete`) }}</span>
                                                </b-dropdown-item>
                                            </b-dropdown>
                                        </b-card-footer>
                                    </b-card>
                                </b-col>
                            </b-row>
                        </div>
                    </b-tab>
                </b-tabs>

                <div class="mx-1 mb-2">
                    <b-row>
                        <!-- <b-col> -->
                        <!-- </b-col> -->
                        <b-col
                            cols="12"
                            sm="6"
                            class="d-flex align-items-center justify-content-center justify-content-sm-start"
                        >
                            <b-form-checkbox
                                class="d-inline-block mx-1"
                                @change="onSelectAll"
                                v-model="selectAllStatus"
                            >
                                {{ i18nT(`All`) }}
                            </b-form-checkbox>
                            <span class="text-muted">{{ i18nT(`Showing`) }} {{
                                    dataMeta.from
                                }} {{ i18nT(`to`) }} {{ dataMeta.to }} {{ i18nT(`of`) }} {{
                                    dataMeta.of
                                }} {{ i18nT(`entries`) }}</span>
                        </b-col>
                        <!-- Pagination -->
                        <b-col
                            cols="12"
                            sm="6"
                            class="d-flex align-items-center justify-content-center justify-content-sm-end"
                        >
                            <b-pagination
                                v-model="currentPage"
                                :total-rows="totalDocuments"
                                :per-page="perPage"
                                first-number
                                last-number
                                class="mb-0 mt-1 mt-sm-0"
                                prev-class="prev-item"
                                next-class="next-item"
                            >
                                <template #prev-text>
                                    <feather-icon
                                        icon="ChevronLeftIcon"
                                        size="18"
                                    />
                                </template>
                                <template #next-text>
                                    <feather-icon
                                        icon="ChevronRightIcon"
                                        size="18"
                                    />
                                </template>
                            </b-pagination>

                        </b-col>

                    </b-row>
                </div>
            </div>
        </b-card>
        <invoice-sidebar-add-payment
            :visible="isAddPaymentVisible"
            :invoice-id="paymentData.InvoiceId"
            :due-amount="paymentData.DueAmount ? parseFloat(paymentData.DueAmount) : 0"
            :payment-record="paymentData"
            :currency="paymentData.Currency ? paymentData.Currency : 'EUR'"
            :cur-locale="locale"
            :invoice-status="paymentData.PaidStatus"
            @updated="refetchInvoiceData"
            @close="closeAddPayment"
        />
    </div>
</template>

<script>
import {
    BLink,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BTable,
    BAvatar,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTabs,
    BTab,
    BListGroup,
    BListGroupItem,
    BCardFooter,
    BCardBody,
    BFormCheckbox,
    BFormInput,
    VBTooltip
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import usePaymentsList from './usePaymentsList'
import {avatarText} from '@core/utils/filter'
import {dictToSelectArray} from '@core/utils/utils'
import router from '@/router'
import useAuth from '@/auth/useAuth'
import Ripple from 'vue-ripple-directive'
import InvoiceSidebarAddPayment from "@/views/apps/invoice/InvoiceSidebarAddPayment.vue";



export default {
    components: {
        BLink,
        BRow,
        BCol,
        BFormGroup,
        BCard,
        BButton,
        BTable,
        BAvatar,
        BDropdown,
        BDropdownItem,
        BPagination,
        InvoiceSidebarAddPayment,
        BTabs,
        BTab,
        BListGroup,
        BListGroupItem,
        BCardFooter,
        BCardBody,
        vSelect,
        BFormCheckbox,
        BFormInput
    },
    directives: {
        Ripple,
        'b-tooltip': VBTooltip
    },
    props: {
        selectedFinanceType: {
            type: String
        }
    },
    data() {
        const displayExtentControls =
            useAuth.getCurrentSoftware() != 'ca' &&
            useAuth.getCurrentSoftware() != 'em'
        return {
            PaymentTypeDict: [],
            isAddPaymentVisible: false,
            locale: this.$i18n.locale,
            paymentData: {},
            invoiceTypes: [],
            paymentCategoryList: [],
            currencies: [],
            paymentTypes: [],
            pagination: {},
            selectedItems: [],
            currentItems: [],
            selectAllStatus: false,
            displayExtentControls,
            avalableFields: [
                {label: this.i18nT(`Payment date`), key: 'CreatedAt', visible: true},
                {label: this.i18nT(`Amount`), key: 'Amount', visible: true},
                {label: this.i18nT(`Payment method`), key: 'PaymentType', visible: true},
            ],
            documentFilters: [
                {value: '', text: this.i18nT(`All`)},
                {value: 'created', text: this.i18nT(`Created by me`)},
                {value: 'shared', text: this.i18nT(`Shared by me`)}
            ],
        }
    },
    mounted() {
        if(this.isInvoiceList) {
            this.invoiceType = {text: this.i18nT(`Income`), value: '1'};
        }
    },

    created() {

        let storePreferences = useAuth.getColumnPreferences('RecordedPaymentsTemplates');
          if(storePreferences) {
            this.avalableFields = storePreferences
        }

        this.$http.get(`system/invoicePaymentTypes`).then((resp) => {
            this.PaymentTypeDict = resp.data.data;
        });

        this.$http.get(`system/invoicePaymentTypes`).then(({data}) => {
            this.paymentTypes = dictToSelectArray(data.data)
            this.paymentTypes.unshift({
                value: -1,
                text: this.i18nT(`All types`)
            })
        })


        this.$http.get(`system/financeTypes`).then(({data}) => {
            this.invoiceTypes = dictToSelectArray(data.data)
            this.invoiceTypes.unshift({
                value: '-1',
                text: this.i18nT(`All types`)
            })
        })
        this.$http.get(`invoices/categories`).then(({data}) => {
            this.paymentCategoryList = dictToSelectArray(data.data)
            this.paymentCategoryList = data.data.map(category => {
                return {
                    value: category.Id,
                    text: category.Label,
                    fullData: category
                }
            })
            this.paymentCategoryList.unshift({
                value: '-1',
                text: this.i18nT(`Any`)
            })
        })


        let filterString = '';
        if(this.isInvoiceList){
            filterString = '?invoicing=1';
        }

        this.$http.get(`system/currencies`).then(({data}) => {
            this.currencies = dictToSelectArray(data.data)
            this.currencies.unshift({
                value: -1,
                text: this.i18nT(`Any`)
            })
        })

    },
    methods: {
        setPaymentInvoiceObj(paymentObj) {
            console.log("Payment: ", paymentObj)
            this.paymentData = paymentObj
            this.openAddPaynmentModal()
        },
        openAddPaynmentModal() {
            if(this.isAddPaymentVisible) {
                this.isAddPaymentVisible = false;
            }
            setTimeout(() => {
                this.isAddPaymentVisible = true;
            }, 0);
        },
        closeAddPayment() {
            this.isAddPaymentVisible = false;
        },
        refetchInvoiceData() {
            this.refetchData()
        },
        paymentTypeIcon(type) {
            switch (type) {
                case 'cash':
                    return 'money-bill-alt'
                case 'sepa_debit':
                    return 'piggy-bank'
                case 'card':
                    return 'credit-card'
                case 'bank':
                    return 'university'
                case 'reimbursement':
                    return 'exchange-alt'
                default:
                    return 'money-bill-alt'
            }
        },

        getCategoryName(typeId) {
            const invoiceType = this.paymentCategoryList.find(type => type.value == typeId)
            return invoiceType ? invoiceType.text : ''
        },
        getTypeName(typeId) {
            const invoiceType = this.invoiceTypes.find(type => type.value == typeId)
            return invoiceType ? invoiceType.text : ''
        },
        getAssigneeName(assignee) {
            const assigneeList = Object.values(assignee)
            return assigneeList.map(ass => ass.Label).join(', ')
        },
        getAvatarName(name) {
            if (name) {
                return name
                    .split(' ')
                    .map(namePart => {
                        return namePart[0]
                    })
                    .join('')
                    .toUpperCase()
            } else {
                return ''
            }
        },
        onTableRefresh() {
            this.selectedItems = []
        },
        onCopy(element) {
            this.$http
                .post(`invoices/copy?id=${element.item.Id}`)
                .then(() => {
                    this.refetchData()
                })
        },
        onDelete(task) {
            this.$swal({
                title: this.i18nT(`Are you sure?`),
                text: this.i18nT(`You won't be able to revert this!`),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: this.i18nT(`Yes, delete it!`),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1'
                },
                buttonsStyling: false
            }).then(result => {
                if (result.value) {
                    this.$http.delete(`invoices/invoicePayment?id=${task.item.Id}`).then(() => {
                        this.$swal({
                            icon: 'success',
                            title: this.i18nT(`Deleted!`),
                            text: this.i18nT(`The document has been deleted.`),
                            customClass: {
                                confirmButton: 'btn btn-success'
                            }
                        })
                        this.refetchData()
                    })
                }
            })
        },
        onBulkCopy() {
            this.$http
                .post(
                    `invoices/copy?invoice_id=${this.selectedItems.join(',')}`
                )
                .then(() => {
                    this.selectedItems = []
                    this.selectAllStatus = false
                    this.refetchData()
                })
        },
        onBulkDelete() {
            this.$swal({
                title: this.i18nT(`Are you sure?`),
                text: this.i18nT(`You won't be able to revert this!`),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: this.i18nT(`Yes, delete it!`),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1'
                },
                buttonsStyling: false
            }).then(result => {
                if (result.value) {
                    this.$http
                        .delete(`invoices/invoicePayment?id=${this.selectedItems.join(',')}`)
                        .then(() => {
                            this.$swal({
                                icon: 'success',
                                title: this.i18nT(`Deleted!`),
                                text:  this.i18nT(`Selected invoice(s) were deleted!`),
                                customClass: {
                                    confirmButton: 'btn btn-success'
                                }
                            })
                            this.selectedItems = []
                            this.selectAllStatus = false
                            this.refetchData()
                        })
                }
            })
        },
        onSelectAll(val) {
            if (val) {
                this.selectedItems = this.currentItems.map(item => item.Id)
            } else {
                this.selectedItems = []
            }
        },
        onExportExcel() {
        },
        onExportCsv() {
        }
    },
    watch: {
        selectedFinanceType: {
            handler: function (newDocType, oldDocType) {

                if(oldDocType != newDocType){
                    if (newDocType == 1 || newDocType == 2) {
                        this.$http.get(
                            `invoices/categories?type=`+newDocType
                        ).then(({data}) => {
                            this.paymentCategoryList = data.data.map(category => {
                                return {
                                    value: category.Id,
                                    text: category.Label,
                                    fullData: category
                                }
                            })
                            this.paymentCategoryList.unshift({
                                value: '-1',
                                text: this.i18nT(`Any`)
                            })
                            this.category = {value: '-1', text: this.i18nT(`Any`)};

                        })
                    } else {
                        this.$http.get(
                            `invoices/categories`
                        ).then(({data}) => {
                            this.paymentCategoryList = data.data.map(category => {
                                return {
                                    value: category.Id,
                                    text: category.Label,
                                    fullData: category
                                }
                            })
                            this.paymentCategoryList.unshift({
                                value: '-1',
                                text: this.i18nT(`Any`)
                            })
                            this.category = {value: '-1', text: this.i18nT(`Any`)};
                        })
                    }
                }

            }
        },
        invoiceType(newVal) {
            if (newVal.value === '-1') {
                this.selectedFinanceType = ''
            } else {
                this.selectedFinanceType = newVal.value
            }
        }
    },
    setup() {
        // const statusOptions = [
        //   'Downloaded',
        //   'Draft',
        //   'Paid',
        //   'Partial Payment',
        //   'Past Due',
        // ]

        let isInvoiceList = router.currentRoute.name === 'invoices'

        const {
            fetchDocuments,
            tableColumns,
            perPage,
            currentPage,
            totalDocuments,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refDocumentListTable,
            statusFilter,
            invoiceType,
            category,
            assignedTo,
            currency,
            paymentType,
            documents,
            documentFilter,
            refetchData
        } = usePaymentsList(router.currentRoute.meta.template === 1)

        return {
            fetchDocuments,
            tableColumns,
            perPage,
            currentPage,
            totalDocuments,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refDocumentListTable,
            statusFilter,
            invoiceType,
            category,
            assignedTo,
            currency,
            paymentType,
            avatarText,
            documents,
            documentFilter,
            isInvoiceList,
            refetchData
        }
    },
    computed: {
        computedColumns() {
            let col = [...this.tableColumns]
            for(let i = 0; i < this.avalableFields.length; i++) {
              if(!this.avalableFields[i].visible) {
                col = col.filter(c => c.key !== this.avalableFields[i].key)
              }
            }
            useAuth.setColumnPreferences('RecordedPaymentsTemplates', this.avalableFields);
            return col
        },
        emptyHtml() {
            return "<img src='" + require('@/assets/images/pages/error.svg') + "' alt='Error page' class='img-fluid' width='300'><br/><br/><h4>" + this.i18nT(`No matching records found`) + "</h4>";
        },
    }

}
</script>

<style lang="scss">
.task-container {
    .task-item {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .line-item {
        margin-top: 4px;
    }
}
.sk-document-label{
  max-width: 150px;
  width: 100%;
}
</style>
